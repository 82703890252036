
const BASE_URL = ' https://opzcrx9eyj.execute-api.us-west-2.amazonaws.com/Prod/practice-my-kid/'
export const LOGIN_URL = BASE_URL+'login'
export const REGISTRATION_URL = BASE_URL+'register'
export const ACCESS_TOKEN_URL = BASE_URL+'access_token'
export const TESTS_URL = BASE_URL+'tests'
export const ADD_KID_URL = BASE_URL+'add_kid'
export const CHANGE_PASSWORD_URL = BASE_URL+'change_password'
export const REQUEST_TEMPORARY_AUTH_CODE = BASE_URL+'send_code'

export const HEADER_KEY_AUTHORIZATION = 'Authorization'
export const QUERY_PARAMS_KEY_KIDS_ID = 'kids_id'