import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addNewKidAction, clearStateAction } from "../actions/actions"
import Progressbar from "../components/progress-bar/Progressbar"
import { apiCallComplete, apiCallFailureMsg, apiCallInProgress, kidsNames } from "../reducers/accountReducer"
import { accessToken } from "../reducers/loginReducer"
import './MyForm.css'

export default function AddKid(props) {
    const [kidsName, setKidsName] = useState('')
    const [error, setError] = useState('')
    const nameOfTheKids = useSelector(kidsNames)
    const token = useSelector(accessToken)
    const isApiCallInProgres = useSelector(apiCallInProgress)
    const isApiCallComplate = useSelector(apiCallComplete)
    const apiError = useSelector(apiCallFailureMsg)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(clearStateAction())
    }, [])


    if (isApiCallInProgres) {
        return < Progressbar />
    } 
    console.log('apiError: '+apiError)
    return (
        <div className="myFormComponent">
            <div className="myFormSuccessText">{(isApiCallComplate && !apiError)? "Successfully added the kid: "+kidsName : ''}</div>
            <div className="myFormErrorText">{error ? error : apiError}</div>
            <input className="myFormInputText"
                type='text'
                placeholder='kidsName'
                value={kidsName}
                onChange={(event) => setKidsName(event.target.value)}
            />
            <input className="myFormButton"
                type='button'
                value='Add Kid'
                onClick={() => {
                    console.log('addKidButton Click')
                    console.log(nameOfTheKids)
                    setError('')
                    if (!kidsName) {
                        setError('Please enter the kids name')
                    } else if (nameOfTheKids.includes(kidsName)) {
                        setError('This kids name alreay exists')
                    } else {
                        dispatch(addNewKidAction(token, kidsName))
                    }
                }}    
            />
        </div>
    )
}