export const ACTION_TYPE_CLEAR_STATE = 'ACTION_TYPE_CLEAR_STATE'

export const ACTION_TYPE_LOGIN_REQUEST = 'ACTION_TYPE_LOGIN_REQUEST'
export const ACTION_TYPE_LOGIN_RESPONSE = 'ACTION_TYPE_LOGIN_RESPONSE'
export const ACTION_TYPE_LOGIN_FAILURE = 'ACTION_TYPE_LOGIN_FAILURE'
export const ACTION_TYPE_ACCESS_TOKEN_VALID = 'ACTION_TYPE_ACCESS_TOKEN_VALID'
export const ACTION_TYPE_ACCESS_TOKEN_INVALID = 'ACTION_TYPE_ACCESS_TOKEN_INVLAID'
export const ACTION_TYPE_VALIDATE_ACCESS_TOKEN = 'ACTION_TYPE_VALIDATE_ACCESS_TOKEN'
export const ACTION_TYPE_LOG_OUT = 'ACTION_TYPE_LOG_OUT'

export const ACTION_TYPE_REGISTRATION_REQUEST = 'ACTION_TYPE_REGISTRATION_REQUEST'
export const ACTION_TYPE_REGISTRATION_SUCCESS = 'ACTION_TYPE_REGISTRATION_SUCCESS'
export const ACTION_TYPE_REGISTRATION_FAILURE = 'ACTION_TYPE_REGISTRATION_FAILURE'

export const ACTION_TYPE_UPDATE_ACCOUNT_INFORMATION = 'ACTION_TYPE_UPDATE_ACCOUNT_INFORMATION'
export const ACTION_TYPE_CLEAR_ACCOUNT_INFORMATION = 'ACTION_TYPE_CLEAR_ACCOUNT_INFORMATION'

export const ACTION_TYPE_SELECT_KID = 'ACTION_TYPE_SELECT_KID'

export const ACTION_TYPE_ADD_NEW_KID = 'ACTION_TYPE_ADD_NEW_KID'
export const ACTION_TYPE_ADD_NEW_KID_SUCCESSFUL = 'ACTION_TYPE_ADD_NEW_KID_SUCCESSFUL'
export const ACTION_TYPE_ADD_NEW_KID_FAILURE = 'ACTION_TYPE_ADD_NEW_KID_FAILURE'

export const ACTION_TYPE_ADD_NEW_TEST = 'ACTION_TYPE_ADD_NEW_TEST'
export const ACTION_TYPE_ADD_NEW_TEST_SUCCESSFUL = 'ACTION_TYPE_ADD_NEW_TEST_SUCCESSFUL'
export const ACTION_TYPE_ADD_NEW_TEST_FAILURE = 'ACTION_TYPE_ADD_NEW_TEST_FAILURE'

export const ACTION_TYPE_GET_ALL_TESTS = 'ACTION_TYPE_GET_ALL_TESTS'
export const ACTION_TYPE_GET_ALL_TESTS_SUCCESS = 'ACTION_TYPE_GET_ALL_TESTS_SUCCESS'
export const ACTION_TYPE_GET_ALL_TESTS_FAILURE = 'ACTION_TYPE_GET_ALL_TESTS_FAILURE'

export const ACTION_TYPE_CLEAR_TESTS = 'ACTION_TYPE_CLEAR_TESTS'

export const ACTION_TYPE_CHANGE_PASSWORD = 'ACTION_TYPE_CHANGE_PASSWORD'
export const ACTION_TYPE_CHANGE_PASSWORD_SUCCESSFUL = 'ACTION_TYPE_CHANGE_PASSWORD_SUCCESSFUL'
export const ACTION_TYPE_CHANGE_PASSWORD_FAILURE = 'ACTION_TYPE_CHANGE_PASSWORD_FAILURE'

export const ACTION_TYPE_REQUEST_TEMPORARY_AUTH_CODE = 'ACTION_TYPE_REQUEST_TEMPORARY_AUTH_CODE'
export const ACTION_TYPE_REQUEST_TEMPORARY_AUTH_CODE_SUCCESS = 'ACTION_TYPE_REQUEST_TEMPORARY_AUTH_CODE_SUCCESS'
export const ACTION_TYPE_REQUEST_TEMPORARY_AUTH_CODE_FAILURE = 'ACTION_TYPE_REQUEST_TEMPORARY_AUTH_CODE_FAILURE'

export const ACTION_TYPE_IN_PROGRESS = 'ACTION_TYPE_IN_PROGRESS'