export const JSON_KEY_KIDS_ID = 'kidsId'
export const JSON_KEY_TEST_TIME = 'testTime'
export const JSON_KEY_TIME_TAKEN = 'timeTaken'
export const JSON_KEY_OPERAND1_MAX_VALUE = 'operand1MaxVal'
export const JSON_KEY_OPERAND2_MAX_VALUE = 'operand2MaxVal'
export const JSON_KEY_CORRECT_ANSWERS = 'correctAnswers'
export const JSON_KEY_TOTAL_QUESTIONS = 'totalQuestions'
export const JSON_KEY_OPERATION = 'operation'
export const JSON_KEY_QUESTIONS = 'questions'
export const JSON_KEY_QUESTION_OPERAND_1 = 'operand1'
export const JSON_KEY_QUESTION_OPERAND_2 = 'operand2'
export const JSON_KEY_QUESTION_OPERATOR = 'operator'
export const JSON_KEY_QUESTION_ANSWER = 'answer'
export const JSON_KEY_QUESTION_CORRECT_ANSWER = 'correctAnswer'
export const JSON_KEY_TESTS = 'tests'